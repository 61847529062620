import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout';
// import Seo from '../components/seo';
import Helmet from "react-helmet"

export const query = graphql`
    query pressReleases {
        wpcontent {
            pressReleases(first: 99) {
                nodes {
                    id
                    content
                    title
                    mediaLogo
                    publishedDate
                    newsLink
                }
            }
            seo{
                contentTypes {
                    pressRelease {
                        metaDesc
                        title
                    }
                }
            }
        }
    }
`

const pressReleases = ({ data }) => {
    const pressreleases = data.wpcontent.pressReleases.nodes
    const pressReleaseSeo = data.wpcontent.seo.contentTypes.pressRelease
    const pageTitle = pressReleaseSeo.title
    const metaDesc = pressReleaseSeo.metaDesc
    

    return (
        <Layout>
            {/* <Seo title={pageTitle}
                meta={[
                  {
                    name: `description`,
                    content: metaDesc,
                  },
                  {
                    property: `og:title`,
                    content: pageTitle,
                  },
                  {
                    property: `og:description`,
                    content: metaDesc,
                  },
                  {
                    property: `og:type`,
                    content: "website",
                  },
                  {
                    name: `twitter:title`,
                    content: pageTitle,
                  },
                  {
                    name: `twitter:description`,
                    content: metaDesc,
                  },
                ]}
            /> */}
            <Helmet>
                <title>{pageTitle}</title>

                <meta name="description" content={metaDesc} />

                <meta property="og:title" content={pageTitle} />

                <meta property="og:description" content={metaDesc} />

                <meta property="twitter:title" content={pageTitle} />
                        
                <meta property="twitter:description" content={metaDesc} />
                        
                <meta property="og:type" content="website"/>
                        
                <meta property="og:url" content="https://www.vantagecircle.com/in-the-press/"/>

                <meta property="og:site_name" content="Vantage Circle"/>
                <link rel="canonical" href="https://www.vantagecircle.com/in-the-press/"></link>
                
            </Helmet>
            {/* <section className="bg-purple-50 slanted-top mx-auto py-10 pb-0 sm:py-20 sm:pb-0 md:py-40 md:pb-10 relative">
                <div className="container max-w-7xl sm:text-center px-6">
                    <h1 className="hero-title-2">
                        In the <span className="text-orange">News</span>
                    </h1>
                    <p className="section-subtitle">
                    Read our Stories
                    </p>
                </div>
                <img className="absolute z-1 hero-iconsl1 hero-icons-top" src="https://res.cloudinary.com/vantagecircle/image/upload/w_200/gatsbycms/uploads/2021/06/Webinar-Hero-L1.png" alt="In the News" />
                <img className="absolute z-1 hero-iconsl2 hero-icons-bottom" src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2021/06/Webinar-Hero-L2.png" alt="In the News" />
                <img className="absolute z-1 hero-iconsr1 hero-icons-top" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/Webinar-Hero-R1.png" alt="In the News" />
                <img className="absolute z-1 hero-iconsr2 hero-icons-bottom" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/Webinar-Hero-R2.png" alt="In the News" />
            </section>
            <div className="slant-bg bg-purple-50 slant-bg-purple py-2 sm:py-5 md:py-8 lg:py-10"><div className="">&nbsp;</div></div> */}

            <section class="bg-indigo-100  mx-auto py-10 pb-10 sm:py-20 sm:pb-0 md:py-20 md:pb-20 relative"><div class="container max-w-7xl sm:text-center px-6"><h1 class="text-white text-5xl md:text-7xl text-center">In the <span class="text-orange">News</span></h1><p class="pt-5 text-white text-center text-xl">Contact us at <a href="mailto:pr@vantagecircle.com" class="text-orange">pr@vantagecircle.com</a> </p>
</div></section>

            <section className="container px-6 lg:px-0 max-w-6xl mx-auto pt-10 pb-0 sm:pt-10 md:py-20 md:pb-0">
                <article className="md:flex align-center p-5 card-1 w-full rounded-lg shadow-lg">
                    <div className="w-full md:w-1/2 md:pr-10">
                        <img className="rounded-lg" src="https://res.cloudinary.com/vantagecircle/image/upload/uploads/2018/06/img_105829_anjan_pathak_and_partha_neog.jpg" alt="Vantage Circle in Your Story" />
                    </div>
                    <div className="w-full md:w-1/2">
                        <div className="box-subtitle mb-2 mt-6 pb-10 ">Published on May 17, 2021</div>
                        <div className="h-14">
                            <img className="pb-0 max-h-10" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/fe-logo.png" alt="Financial Express"/>
                        </div>
                        <h2 className="section-title font-bold mb-3">Vantage Circle: Keeping employees happy</h2>
                        <div className="learnmore-btn mb-0 text1">
                            <a className="text-purple-500 inline-flex items-center" href="https://www.financialexpress.com/industry/sme/vantage-circle-keeping-employees-happy/2252242/" target="_blank" rel="noopener noreferrer">
                                <span className="a-stroke w-4 bg-purple-500 mr-2 relative"></span>
                                <span className="text-purple-500 font-medium text-sm uppercase">Read now</span>
                                <svg className="w-6 h-6 top-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><polygon fill="currentColor" points="15 12 9 16 9 8"></polygon></svg>
                            </a>
                        </div>
                    </div>
                </article>
            </section>

            <section className="container px-6 lg:px-0 max-w-6xl mx-auto pt-0 py-0 sm:pt-10 md:py-40 md:pt-1">
                <div className="text-center pt-10 md:pt-24 md:pb-10">
                    <h2 className="section-title">
                    Also Featured In
                    </h2>
                </div>
                <div className="mt-10 grid grid-cols-1 gap-4 sm:gap-3 md:gap-9 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                    {pressreleases.map(pressrelease => (
                        <article key={pressrelease.id}>
                            <div className="bg-white p-5 pt-8 card-1 w-full h-full mb-5 md:mb-0 rounded-lg overflow-hidden shadow-lg transform transition duration-500 ease-in-out">
                                <div className="h-14">
                                    <img className="pb-0 max-h-10" src={pressrelease.mediaLogo} alt={pressrelease.title}/>
                                </div>
                                <div className="bg-white py-6 pb-0 h-full">
                                    <div className="box-subtitle mb-2">
                                        Published on <span dangerouslySetInnerHTML={{ __html: pressrelease.publishedDate }}/>
                                    </div>
                                    <h2 className="box-title font-bold mb-3 line-clamp-2" dangerouslySetInnerHTML={{ __html: pressrelease.content }} />
                                    <div className="learnmore-btn mb-0 text1">
                                        <a className="text-purple-500 inline-flex items-center" href={`${pressrelease.newsLink}`} target="_blank" rel="noopener noreferrer">
                                            <span className="a-stroke w-4 bg-purple-500 mr-2 relative"></span>
                                            <span className="text-purple-500 font-medium text-sm uppercase">Read now</span>
                                            <svg className="w-6 h-6 top-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><polygon fill="currentColor" points="15 12 9 16 9 8"></polygon></svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </article>
                    ))}
                </div>
            </section>
            
        </Layout>
    )
}

export default pressReleases

